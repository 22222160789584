import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroVideo from '../components/Sections/HeroVideo'
//import ElectionSurvey from '../components/Sections/ElectionSurvey'
//import RegistrationForm from '../components/Sections/RegistrationForm'
//import RegistrationFormAssociate from '../components/Sections/RegistrationFormAssociate'
//import ScholarshipForm from '../components/Sections/ScholarshipForm'
import MembersInactive from '../components/Sections/MembersInactive'
import CertificateOfCandidacy from '../components/Sections/CertificateOfCandidacy'
import BrandsAndServices from '../components/Sections/BrandsAndServices'
import FacebookPost from '../components/Sections/FacebookPost'
import { getAssetUrl } from '../services/cdn'
import { Container, Row, Col } from 'reactstrap'
import { Container as BlockContainer } from '../components/Blocks'
//import DataPrivacyModal from '../components/Sections/DataPrivacyModal'
//import HouseholdForm from '../components/Sections/HouseholdForm'
//import ElectionAnnouncement from '../components/Sections/ElectionAnnouncement'
import styled from 'styled-components'

const Block = styled.div`
  height: 55vh;
  @media screen and (min-width: 1800px) {
    height: 80vh;
  }
  @media screen and (min-width: 2600px) {
    height: 100vh;
  }
`;

const IndexPage = () => (

  <Layout>
    <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
    <HeroVideo id="main-hero" x={50} y={50} height={null} src="app/Video/anniversary.mp4" posterSrc={getAssetUrl('app/video-poster.png')} loop={true} autoplay muted>
    {/* <HeroVideo id="main-hero" height={null} x={50} y={50} src="app/Video/"  posterSrc={getAssetUrl('app/ASEAN_Banner.jpg')} loop={true} autoplay muted> */}
      <Block />
    </HeroVideo>
    <BlockContainer>
      <Container>
        <Row>
          <Col md="12">
              <h1 className="mb-3">Sorosoro Ibaba Development Cooperative (SIDC)</h1>
              <p>Sorosoro Ibaba Development Cooperative (SIDC) is first established as Sorosoro Ibaba Farmers' Association in March 19, 1969, thru Mr. Victoriano E. Barte. The association was funded initially by 59 members and as of May 2024, the cooperative has 49,033 regular members and 22,912 associate members.</p>
              <p>SIDC grows to a Coorporative providing with a sustainable Lifecycle in Agriculture, Financial Services, Retail and Wholesale as well in Ecotourism -all with participation on their Members.</p>
              <p>Be a Part of SIDC Sorosoro Ibaba Development Cooperative and benefit from the Community.</p>
          </Col>

        </Row>
      </Container>


    </BlockContainer>
   <MembersInactive />
   <CertificateOfCandidacy />
  {/*<RegistrationForm />
  <ElectionAnnouncement/>
    <ElectionSurvey />
    <DataPrivacyModal/>
    <RegistrationFormAssociate/>
    <ScholarshipForm />
    <HouseholdForm />*/}

      <BrandsAndServices />
	  <FacebookPost />
  </Layout>
)

export default IndexPage
